<template>
  <el-card class="account">
    <header>
      <div class="account-header flex-between">
        <div>
          <h3>{{ account.serviceProviders.label || 'N/A'}}</h3>
          <label>{{ account.accountNumber }}</label>
        </div>

        <div class="text-right">
          <label>USD</label>
          <div class="amount">
            {{ account.systemAvailableBalance | money(account.currency) }}
          </div>
        </div>
      </div>
    </header>

    <div class="mt-20" :class="card ? 'flex-between' : 'flex-center'">
      <el-button
        v-if="card && (card.cardStatus == 'Active' || card.cardStatus == 'Suspended')"
        type="success"
        size="mini"
        class="view-card-button"
        round
        @click.stop="loadCardModal()">
        <FundsSvg width="14" fill="#fff" /> View Card
      </el-button>
    </div>

    <card-preview v-if="showCard" :account="account" :pinData="showCard" @close="showCard = false" />
  </el-card>
</template>

<script>
import FundsSvg from '@/assets/images/icons/funds.svg'
import CardPreview from '@/views/profile/my-accounts/card/CardPreview'
import DigitalAssetsSvg from '@/assets/images/icons/digital-asset.svg'

export default {
  props: {
    account: {
      type: Object,
      required: true
    }
  },

  components: {
    FundsSvg,
    CardPreview,
    DigitalAssetsSvg
  },

  data () {
    return {
      expanded: false,
      showCard: false
    }
  },

  computed: {
    funds () {
      const funds = [
        {
          currency: this.account.currency,
          availableBalance: this.account.systemAvailableBalance,
          totalBalance: this.account.systemTotalBalance
        }
      ]
      const currencies = ['AUD', 'CAD', 'EUR', 'GBP', 'JPY']

      currencies.forEach(currency => {
        if (this.getCurrencyStatus(currency)) {
          funds.push({
            currency,
            availableBalance: this.getSystemFundsAvailableBalance(currency) || '',
            totalBalance: this.getSystemFundsTotalBalance(currency)
          })
        }
      })

      return funds
    },

    assetsList () {
      return this.$store.state.app.currencies
        .filter(currency => currency.systemCurrencyType === 'Assets')
        .map(currency => currency.currencyNickname)
    },

    card () {
      const cardIdx = this.account.cards?.length - 1
      if (this.account.cards.length <= 0 || !this.account.cards[cardIdx].cardStatus) return null
      return this.account.cards[cardIdx]
    }
  },

  methods: {
    loadCard () {
      return this.$store.dispatch('cards/action', {
        id: this.card?.id,
        action: 'pinToken'
      })
    },

    async loadCardModal () {
      const response = await this.loadCard()
      if (response.success !== false) {
        this.showCard = {
          ...response.action,
          id: response.item?.cardId
        }
      } else {
        this.$notify.error(window.errorMessage)
      }
    },

    getCurrencyStatus (currency) {
      const fundsCurrency = this.account.fundsCurrencies.find(item => item.currency.currencyNickname === currency)
      return fundsCurrency ? fundsCurrency.currencyEnabled : null
    },

    getSystemFundsAvailableBalance (currencyNickname) {
      let fundsCurrencies = this.account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0] ? fundsCurrencies[0].systemAvailableBalance : null
    },

    getSystemFundsTotalBalance (currencyNickname) {
      let fundsCurrencies = this.account.fundsCurrencies || []
      fundsCurrencies = fundsCurrencies.filter(fundsCurrency => {
        return fundsCurrency.currency.currencyNickname === currencyNickname
      })
      return fundsCurrencies[0] ? fundsCurrencies[0].systemTotalBalance : null
    },

    getAssetsSystemAssetAvailableBalance (currencyNickname) {
      let wallets = this.account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0] ? parseFloat(wallets[0].systemAssetAvailableBalance) : 0
    },

    getAssetsSystemAssetTotalBalance (currencyNickname) {
      let wallets = this.account.assetsWallets || []
      wallets = wallets.filter(wallet => {
        return wallet.currency.currencyNickname === currencyNickname
      })
      return wallets[0] ? parseFloat(wallets[0].systemAssetTotalBalance) : 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .account {
    header {
      width: 100%;
      .account-header {
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          color: $title;
        }

        .amount {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: $primary;
        }

        label {
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: $text;
          text-transform: uppercase;
          margin-top: 5px;
        }
      }
    }

    .collapsable-section {
      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: $title;
      }
      .table-wrapper {
        background-color: $bg;
        border-radius: 12px;
        padding: 8px 15px 15px 15px;
      }
    }

    .expand {
      text-align: center;
      color: $link;
    }
  }
  .account + .account {
    margin-top: 15px;
  }

  .view-card-button {
    padding: 4px 8px;
    font-size: 14px;
  }

  .flex-right {
    align-items: center;
    .view-card-button {
      flex: 0;
    }
  }

  @media all and (max-width: 400px) {
   .account .table-value {
      font-size: 14px;
    }
  }
</style>
