<template>
  <div>
    <error-box
      v-if="error"
      :loading="loading" />

    <div
      v-else-if="loading"
      v-loading="loading"
      class="page-loader" />

    <div class="flex-buttons mb-20">
      <el-button class="font16" v-if="remoteDepositFeature" round disabled>
        <DepositSvg :fill="$colors.text200" height="16px" /> Remote Deposit
      </el-button>
      <router-link v-if="withdrawalFeature" :to="{ name: 'Withdrawal' }">
        <el-button class="font16" round>
          <WithdrawalSvg :fill="$colors.info" height="16px" /> Withdrawal
        </el-button>
      </router-link>
    </div>

    <div :top-load-method="load">
      <div v-if="accounts.length">
        <template v-for="account in accounts">
          <account-view
            v-if="account.accountStatus == 'Open' || account.accountStatus == 'Active' || account.accountStatus == 'Restricted'"
            :key="account.id"
            :account="account"
          />
        </template>
      </div>

      <div v-else>
        <empty v-if="!loading && !error" :icon="emptyIcon">You don't have any accounts.</empty>
      </div>

      <template slot="top-block">
        <div>
          <div
            v-if="reloading"
            v-loading="reloading"
            class="basic-loader mb-20" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import PullTo from 'vue-pull-to'
import AccountsIcon from '@/assets/images/icons/accounts.svg'

import DepositSvg from '@/assets/images/icons/remote-deposit.svg'
import WithdrawalSvg from '@/assets/images/icons/withdrawal.svg'
import Empty from '@/components/common/ui/Empty'
import ErrorBox from '@/components/common/ui/ErrorBox'
import AccountView from '@/components/accounts/AccountView'

export default {
  components: {
    Empty,
    PullTo,
    ErrorBox,
    DepositSvg,
    AccountView,
    WithdrawalSvg
  },

  data () {
    return {
      error: null,
      loading: false,
      reloading: false,

      topConfig: {
        pullText: 'Pull to reload',
        triggerText: 'Release to reload',
        loadingText: 'Loading...',
        doneText: '',
        failText: ''
      },
      iconLink: '',

      emptyIcon: AccountsIcon
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    systemFeatures () {
      return this.$store.state.app.systemFeatures
    },

    remoteDepositFeature () {
      return this.systemFeatures['Remote Deposit']
    },

    withdrawalFeature () {
      return this.systemFeatures.Withdrawal
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: ''
    })
  },

  methods: {
    stateChange (state) {
      if (state === 'pull' || state === 'trigger') {
        this.iconLink = '#icon-arrow-bottom'
      } else if (state === 'loading') {
        this.iconLink = '#icon-loading'
      } else if (state === 'loaded-done') {
        this.iconLink = '#icon-finish'
      }
    },

    async load (loaded) {
      this.reloading = true
      try {
        await this.$store.dispatch('app/loadData', true)
      } catch (e) {
        console.error(e)
        this.error = true
      } finally {
        loaded('done')
        this.reloading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $title;
    margin-bottom: 10px;
  }

  @media all and (max-width: 380px) {
    .flex-buttons::v-deep .el-button {
      text-align: center;
      svg {
        display: block;
        margin: 0 auto 5px auto;
      }
    }
  }
</style>
